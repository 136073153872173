/* eslint-disable no-new-object */
// in src/users.tsx
import { useEffect, useState } from "react";
import { TextInput, SimpleForm, AutocompleteInput, Create, Toolbar, SaveButton, useRedirect, useNotify, SelectArrayInput, regex, required } from "react-admin";
import DataProvider from "../../admin/DataProvider";
import CustomAutoCompleteInput from "../../components/CustomInputs/CustomAutoCompleteInput";
import CustomTextInput from "../../components/CustomInputs/CustomTextInput";

const JobsCreate = (props: any) => {
  let duplication = localStorage.getItem("duplication") ? localStorage.getItem("duplication") : "false";
  const user = localStorage.getItem("user");
  const notify = useNotify();
  const [companies, setCompanies] = useState([]);
  const [skillsPath, setSkillsPath] = useState([])
  const validateCode = regex(/[A-Za-z]{5}[0-9]{2}/, 'Le code doit contenir 5 lettres et 2 chiffres, exemple : TRuyi56')
  useEffect(() => {
    DataProvider.getList("companies").then(res => setCompanies(res.data));
    DataProvider.getList("skills").then(res => setSkillsPath(res.data));
  }, [])
  const companiesTab: Object[] = companies.map((e: any) => {
    return new Object({ id: e.id, name: e.name });
  })
  const skillsTab: Object[] = skillsPath.map((e: any) => {
    return new Object({ id: e.id, name: e.title });
  })
  const skillsNumberValidation = (values: any) => {
    return (values && values.length <= 7 && values.length >= 1) ? undefined : "Merci de sélectionner 1 à 7 compétences maximum."
  }
  const JobToolbar = (props: any) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const handleClick = () => {
      localStorage.setItem("duplication", "false");
    }
    return (
      <Toolbar {...props} >
        <SaveButton label={duplication === "true" ? "Créer un nouveau poste" : "Enregistrer"} onClick={handleClick} alwaysEnable mutationOptions={{
          onSuccess: data => {
            notify('ra.notification.created', {
              type: 'info',
              messageArgs: { smart_count: 1 },
            });
            redirect(false);
          }
        }
        } />
      </Toolbar>
    )
  };
  return (
    <Create mutationOptions={{ meta: { duplication: duplication } }} redirect="list">
      <SimpleForm toolbar={<JobToolbar />}>
        <CustomTextInput variant="outlined" source="title" label="Nom du poste" validate={[required()]} />
        <div style={{ display: 'flex', width: '100%' }}>
          <CustomAutoCompleteInput variant="outlined" source="company_id" choices={companiesTab} label="Nom de l'entreprise" style={{ marginRight: '5%' }} />
          <CustomTextInput variant="outlined" source="new_company" label="Nouvelle entreprise" />
        </div>
        {duplication === "false" ? (<SelectArrayInput validate={skillsNumberValidation} variant="outlined" source="skill_ids" label="Compétences" choices={skillsTab} />) : (<CustomAutoCompleteInput style={{ display: 'none' }} label="Parcours de compétences" source="skills_path.id" choices={skillsTab} />)}
        <CustomTextInput variant="outlined" source="code" label="Code du poste" validate={validateCode} />
      </SimpleForm>
    </Create >
  )
}
export default JobsCreate;

