//dev

//const API_URL = "https://coss-candidate-manager.bluelogic.fr/api";
//export const URL_SERVER = "https://coss-candidate-manager.bluelogic.fr";

//local
//const API_URL = "http://192.168.0.41:8000/api";
//export const URL_SERVER = "http://192.168.0.41:8000";

// const API_URL = "http://192.168.192.36:8000/api";
// export const URL_SERVER = "http://192.168.192.36:8000";

//const API_URL = "http://192.168.1.103:8000/api";
//export const URL_SERVER = "http://192.168.1.103:8000";

//prod

const API_URL = "https://manager.coss-candidate.com/api";
export const URL_SERVER = "https://manager.coss-candidate.com";

export default API_URL;
