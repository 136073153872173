/* eslint-disable no-new-object */
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import { useState } from "react";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { fetchUtils } from 'react-admin';
import API_URL from '../../utils/var';
import { useParams } from "react-router-dom"
import CheckedLogo from '../../assets/check-symbol-4794.svg'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import './FeedbackForm.css'
import Radio from '@mui/material/Radio';
import { Container, Grid } from '@material-ui/core';
import image from "../../assets/header.png";



const httpClient = fetchUtils.fetchJson;

const labels: { [index: string]: string } = {
    '-1': 'Sans opinion',
    1: 'Jamais',
    2: 'Peu souvent',
    3: 'Souvent',
    4: 'Très souvent',
    5: 'Tout le temps',
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FeedbackForm = ({ data, answered }: any) => {
    const candidatName = data.length > 0 && (data[0].recipient.candidature.candidate.firstname);
    const jobName = data.length > 0 && (data[0].recipient.candidature.job.title)
    const numberTabs = data.length;
    console.log({ numberTabs })
    const recipientId = data.length > 0 && data[0].recipient.id
    const { token } = useParams();
    //Creation d'un objet pour l'onglet des informations additionnelles
    const dataWithEmpty = data.concat(new Object({}));
    //création du gros tableau avec les réponses
    const [feedbackAnswer, setFeedbackAnswer] = useState<any>([]);
    const [tab, setTab] = useState(0);
    let feedbackStatus = 1;
    const [openToast, setOpenToast] = useState(false);
    const [openAnswer, setOpenAnswer] = useState(false);
    const [openTags, setOpenTags] = useState(false);
    const [openAdditional, setOpenAdditional] = useState(false);
    const [responses, setResponses] = useState<any>([]);

    // croix pour ignorer
    const [isClicked, setIsClicked] = useState(false);

    //state additionnal form
    const [recommended, setRecommended] = useState(true);
    const [showName, setShowName] = useState(false);
    const [keepData, setKeepData] = useState(false);
    const [jobNameForm, setJobNameForm] = useState("");
    const [timeSpent, setTimeSpent] = useState("");
    const [comments, setComments] = useState("");

    //Point forts points faibles 
    const [strenghtsOutlined, setStrenghtsOutlined] = useState<any>([]);
    const [weaknessesOutlined, setWeaknessesOutlined] = useState<any>([]);
    //feedback envoyé 
    const [feedbackSent, setFeedbackSent] = useState(false);

    const handleNextClick = (data: any) => {
        const strengths: string[] = strenghtsOutlined.filter((tag: any) => tag.value === true).map((tag: any) => tag.tagname);
        const weaknesses: string[] = weaknessesOutlined.filter((tag: any) => tag.value === true).map((tag: any) => tag.tagname);
        //si plus de 3 notations avec étoiles apparition du toast
        const tooMuchStars = responses.filter((element: any) => element.score === 5)
        if (tooMuchStars.length > 3 && feedbackStatus !== 2) {
            setOpenToast(true);
        } else if (data[tab].skill.questions.length !== responses.length && feedbackStatus !== 2) {
            setOpenAnswer(true);
        } else if (strengths.length === 0 && feedbackStatus !== 2) {
            setOpenTags(true);
        }
        else {
            const filteredFeedback = feedbackAnswer.filter((feedback: any) => feedback.id !== data[tab].id);
            setFeedbackAnswer(filteredFeedback.concat({
                id: data[tab].id,
                status: feedbackStatus,
                answers: { questions: responses },
                strengths: strengths,
                weaknesses: weaknesses,
            }))
            feedbackStatus = 1
            if (feedbackAnswer[tab + 1] !== undefined) {
                setResponses(feedbackAnswer[tab + 1].answers.questions);
                if (feedbackAnswer[tab + 1].weaknesses.length > 0) {
                    let previousWeaknesses: Object[] = []
                    feedbackAnswer[tab + 1].weaknesses.forEach((element: any) => previousWeaknesses.push(new Object({ tagname: element, value: true })))
                    setWeaknessesOutlined(previousWeaknesses);
                } else {
                    setWeaknessesOutlined([]);
                }
                if (feedbackAnswer[tab + 1].strengths.length > 0) {
                    let previousStrengths: Object[] = []
                    feedbackAnswer[tab + 1].strengths.forEach((element: any) => previousStrengths.push(new Object({ tagname: element, value: true })))
                    setStrenghtsOutlined(previousStrengths);
                } else {
                    setStrenghtsOutlined([]);
                }
                setWeaknessesOutlined(feedbackAnswer[tab + 1].weaknesses);
                setStrenghtsOutlined(feedbackAnswer[tab + 1].strengths);
            } else {
                setResponses([]);
                setWeaknessesOutlined([]);
                setStrenghtsOutlined([]);
            }
            setTab(tab + 1);
        }

    };
    const sendFeedback = async () => {
        if (timeSpent !== "" && jobNameForm !== "" && comments !== "") {
            const url = `${API_URL}/feedback/fr/${recipientId}/${token}`
            httpClient(url, {
                method: 'PATCH',
                body: JSON.stringify(new Object({
                    feedback_data: feedbackAnswer, additional: {
                        duration: timeSpent,
                        jobTitle: jobNameForm,
                        recommended: recommended,
                        comment: comments,
                        accept_show_name: showName,
                        accept_keep_data: keepData,
                    }
                })),
            }).then(() => setFeedbackSent(true))
        }
        else {
            setOpenAdditional(true);
        }
    }
    const IgnoreFeedback = async (data: any) => {
        feedbackStatus = 2;
        setResponses([]);
        handleNextClick(data);
    }
    const handlePreviousClick = () => {
        if (feedbackAnswer[tab - 1].weaknesses.length > 0) {
            let previousWeaknesses: Object[] = []
            feedbackAnswer[tab - 1].weaknesses.forEach((element: any) => previousWeaknesses.push(new Object({ tagname: element, value: true })))
            setWeaknessesOutlined(previousWeaknesses);
        } else {
            setWeaknessesOutlined([]);
        }
        if (feedbackAnswer[tab - 1].strengths.length > 0) {
            let previousStrengths: Object[] = []
            feedbackAnswer[tab - 1].strengths.forEach((element: any) => previousStrengths.push(new Object({ tagname: element, value: true })))
            setStrenghtsOutlined(previousStrengths);
        } else {
            setStrenghtsOutlined([]);
        }
        setResponses(feedbackAnswer[tab - 1].answers.questions);
        setTab(tab - 1);
    };
    const getVariantChip = (tag: any, chipTags: any) => {
        if (chipTags.length > 0) {
            if (chipTags.find((item: any) => item.tagname === tag)?.value) {
                return 'filled'
            }
        }
        return 'outlined'
    }
    const getDisabled = (tag: any, tagList: any) => {
        if (tagList.length > 0) {
            if (tagList.find((item: any) => item.tagname === tag)?.value) {
                return true;
            }
        }
        return false;
    }
    const handleStrengths = (index: any, tag: any) => {
        setStrenghtsOutlined((prevState: any) => {
            if (!prevState) {
                return [{ tagname: tag, value: true }];
            } else {
                const existingIndex = prevState.findIndex(
                    (item: any) => item.tagname === tag
                );
                if (existingIndex !== -1) {
                    const newState = [...prevState];
                    newState[existingIndex] = {
                        tagname: tag,
                        value: !newState[existingIndex].value,
                    };
                    return newState;
                } else {
                    return [...prevState, { tagname: tag, value: true }];
                }
            }
        });
    };
    const handleWeaknesses = (index: any, tag: any) => {
        setWeaknessesOutlined((prevState: any) => {
            if (!prevState) {
                return [{ tagname: tag, value: true }];
            } else {
                const existingIndex = prevState.findIndex(
                    (item: any) => item.tagname === tag
                );
                if (existingIndex !== -1) {
                    const newState = [...prevState];
                    newState[existingIndex] = {
                        tagname: tag,
                        value: !newState[existingIndex].value,
                    };
                    return newState;
                } else {
                    return [...prevState, { tagname: tag, value: true }];
                }
            }
        });
    };
    const handleRatingChange = async (questionId: any, event: any, newRating: any) => {
        setIsClicked(true);
        const updatedResponse = { id: questionId, score: newRating };
        const filteredResponses = responses.filter((response: any) => response.id !== questionId);
        setResponses(filteredResponses.concat(updatedResponse));
    };
    const getRate = (question: any, idFeedback: any) => {
        const activeFeeback = feedbackAnswer.find((element: any) => element.id === idFeedback)
        if (responses.length > 0) {
            const responseAlreadyGiven = responses.find((element: any) => element.id === question.id)
            if (responseAlreadyGiven && responseAlreadyGiven.score !== -1) {
                return responseAlreadyGiven.score;
            }
            else if (responseAlreadyGiven && responseAlreadyGiven.score === -1) {
                return '-1';
            }
        } else if (activeFeeback && activeFeeback.answers.questions.length > 0) {
            const responseAlreadyGiven = activeFeeback.answers.questions.find((element: any) => element.id === question.id)
            if (responseAlreadyGiven && responseAlreadyGiven.score !== -1) {
                return responseAlreadyGiven.score;
            } else if (responseAlreadyGiven && responseAlreadyGiven.score === -1) {
                return '-1';
            }
        }
        return 0;
    };
    console.log({ answered })
    return (
        <>
            {!feedbackSent && !answered ? (
                <>
                    {data !== undefined && numberTabs > 0 ? (
                        <>
                            <Container maxWidth='xl' className='container-feedback'>
                                <div className='header'>
                                    <div className='titleLhh'>LHH</div>
                                    <img src={image} alt="logo" style={{ height: "80%" }}></img>
                                </div>

                                <Tabs value={tab}>
                                    {data.map((item: any, index: any) => {
                                        return (
                                            <Tab {...a11yProps(index)} label={item.skill.title} style={{ color: '#581f64', fontWeight: 'bold' }} />
                                        )
                                    })}
                                    <Tab {...a11yProps(data.length)} label={"Informations complémentaires"} style={{ color: '#581f64', fontWeight: 'bold' }}></Tab>
                                </Tabs>
                                {data && dataWithEmpty && dataWithEmpty.map((item: any, index: any) => {
                                    return (
                                        <>
                                            <TabPanel value={tab} index={index}>
                                                {index === data.length || tab === data.length ? (
                                                    <>
                                                        <p style={{ color: '#581f64', marginBottom: '2%' }}>Candidature pour le poste de {jobName}</p>
                                                        <br />
                                                        <Grid container>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                <span style={{ marginRight: '5%' }}>Temps passé par le candidat dans un poste similaire dans votre entreprise *</span>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '4%' }}>
                                                                <TextField
                                                                    id="timeSpent"
                                                                    label="Durée *"
                                                                    variant="outlined"
                                                                    value={timeSpent}
                                                                    style={{ maxWidth: '100%' }}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTimeSpent(event.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                <span style={{ marginRight: '5%' }}>Intitulé du poste occupé *</span>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '4%' }}>
                                                                <TextField
                                                                    id="jobNameForm"
                                                                    label="Intitulé du poste *"
                                                                    variant="outlined"
                                                                    value={jobNameForm}
                                                                    style={{ maxWidth: '100%' }}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setJobNameForm(event.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                <span style={{ marginRight: '5%' }}>Commentaire additionnel au sujet du candidat *</span>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '4%' }}>
                                                                <TextField
                                                                    id="comments"
                                                                    multiline
                                                                    value={comments}
                                                                    rows={4}
                                                                    style={{ maxWidth: '100%', minWidth: '80%' }}
                                                                    label="Commentaires"
                                                                    variant="outlined"
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setComments(event.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                <span style={{ marginRight: '5%' }}>Est-ce que vous recommanderiez le candidat ? *</span>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'left', paddingTop: '0.5%', paddingLeft: '4%' }}>
                                                                <div style={{}}>
                                                                    <span>Oui</span>
                                                                    <Radio
                                                                        className='radios'
                                                                        checked={recommended}
                                                                        onChange={() => setRecommended(true)}
                                                                    />
                                                                    <span>Non</span>
                                                                    <Radio
                                                                        className='radios'
                                                                        checked={!recommended}
                                                                        onChange={() => setRecommended(false)}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <div className='checkboxGroup'>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked
                                                                        checked={showName}
                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowName(event.target.checked)}
                                                                    />
                                                                }
                                                                className='checkbox'
                                                                label="Autorisez-vous LHH à faire figurer votre nom dans la prise de référence"
                                                            />
                                                            <br />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked
                                                                        checked={keepData}
                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setKeepData(event.target.checked)}
                                                                    />
                                                                }
                                                                className='checkbox'
                                                                label="Autorisez-vous LHH à conserver vos données dans le cadre de futurs recrutements"
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>
                                                        <strong className='titleSkill'>{item.skill.title}</strong>
                                                        <div style={{ marginTop: '2%' }}>
                                                            {item.skill.questions.map((question: any, index: any) => {
                                                                return (
                                                                    <>
                                                                        <p>{question.title.replace("_NAME_", candidatName)}</p>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2%' }}>
                                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                                                <Rating emptyIcon={<StarBorderIcon className='stars' />} className='stars' getLabelText={() => getLabelText(getRate(question, data[tab].id))} name={question.title} value={getRate(question, data[tab].id)} onChange={(event, newRating) => handleRatingChange(question.id, event, newRating)} style={{ marginRight: '1em' }} color='blue' />
                                                                                <CloseIcon className='close-icon' key={question.id} color={getRate(question, data[tab].id) === '-1' ? 'primary' : 'disabled'} onClick={(event) => handleRatingChange(question.id, event, -1)} style={{ marginRight: '1em' }} />
                                                                                {getRate(question, data[tab].id) !== null && (
                                                                                    <div style={{ position: 'absolute', left: '100%' }}>
                                                                                        {labels[getRate(question, data[tab].id)]}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                                {item.skill && item.skill.tags && item.skill.tags.length > 0 && (
                                                    <Stack direction="column" spacing={0} >
                                                        <strong>Quels sont les points forts de {candidatName} ?</strong>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                                                            {item.skill.tags.map((tag: any, index: any) => {
                                                                return (
                                                                    <Chip
                                                                        key={index}
                                                                        variant={getVariantChip(tag, strenghtsOutlined)}
                                                                        disabled={getDisabled(tag, weaknessesOutlined)}
                                                                        onClick={() => handleStrengths(index, tag)}
                                                                        label={tag}
                                                                        style={{ margin: '1%' }}
                                                                        color='primary'
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    </Stack>
                                                )}
                                                {item.skill && item.skill.tags && item.skill.tags.length > 0 && (
                                                    <Stack direction="column" spacing={1} style={{ margin: '1% 0' }}>
                                                        <strong>Quels sont les points de progrès de {candidatName} ?</strong>
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                                                            {item.skill.tags.map((tag: any, index: any) => {
                                                                return (
                                                                    <Chip
                                                                        key={index}
                                                                        variant={getVariantChip(tag, weaknessesOutlined)}
                                                                        onClick={() => handleWeaknesses(index, tag)}
                                                                        disabled={getDisabled(tag, strenghtsOutlined)}
                                                                        label={tag}
                                                                        style={{ margin: '1%' }}
                                                                        color='primary'
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    </Stack>
                                                )}
                                                <div className='buttonList'>
                                                    {tab > 0 && <Button startIcon={<ArrowBackIosIcon />} className='buttonForm' color='primary' variant='contained' onClick={handlePreviousClick}>Précédent</Button>}
                                                    {tab < numberTabs ? (
                                                        <>
                                                            <Button startIcon={<ArrowForwardIosIcon />} className='buttonForm' color='primary' variant='contained' onClick={() => handleNextClick(data)} >Suivant</Button>
                                                            <Button startIcon={<CloseIcon />} color='primary' variant='outlined' onClick={() => IgnoreFeedback(data)} >Ignorer</Button>
                                                        </>
                                                    ) : <Button onClick={sendFeedback} className='buttonForm' color='primary' variant='contained' startIcon={<SendIcon />}>Envoyer mes réponses</Button>}
                                                </div>
                                            </TabPanel >
                                            <Snackbar open={openToast} onClose={() => setOpenToast(false)}>
                                                <Alert onClose={() => setOpenToast(false)} severity="error" sx={{ width: '100%' }}>
                                                    Vous pouvez seulement mettre 3 notes à 5 étoiles au maximum.
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={openAnswer} onClose={() => setOpenAnswer(false)}>
                                                <Alert onClose={() => setOpenAnswer(false)} severity="error" sx={{ width: '100%' }}>
                                                    Veuillez répondre à toutes les questions.
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={openTags} onClose={() => setOpenTags(false)}>
                                                <Alert onClose={() => setOpenTags(false)} severity="error" sx={{ width: '100%' }}>
                                                    Veuillez sélectionner au moins un point fort.
                                                </Alert>
                                            </Snackbar>
                                            <Snackbar open={openAdditional} onClose={() => setOpenAdditional(false)}>
                                                <Alert onClose={() => setOpenAdditional(false)} severity="error" sx={{ width: '100%' }}>
                                                    Veuillez remplir tous les champs obligatoires.
                                                </Alert>
                                            </Snackbar>

                                        </>
                                    )

                                })}
                            </Container >



                        </>
                    )
                        : (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
                                    <CircularProgress style={{ display: 'flex', height: '' }} />
                                </div>
                            </>
                        )}
                </>


            ) : (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
                    <img src={CheckedLogo} alt="Checked Logo" width="5%" />
                    <Typography mt={5} variant="h5" style={{ textAlign: 'center' }}>Merci d'avoir participé à cette prise de références.</Typography>
                </div>
            )
            }
        </>
    )
}
export default FeedbackForm;